import * as React from "react";
import { FontAwesomeIcon as Fai } from "@fortawesome/react-fontawesome";

// Fix huge icon splash before real size. src:https://fontawesome.com/v5.15/how-to-use/on-the-web/using-with/react#nextjs
import { config } from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-svg-core/styles.css'
config.autoAddCss = false

// Wrapper for huge icon workaround. Add width. src:https://github.com/FortAwesome/react-fontawesome/issues/234#issuecomment-490645030
export const FontAwesomeIcon = ({ icon, ...props}) =>
    <Fai width="16" icon={icon} {...props}/>
    
// Add https://github.com/FortAwesome/react-fontawesome/issues/234#issuecomment-511138314
// if typescript is asking for width prop absence