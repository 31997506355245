import * as React from "react";
import {
    Button,
    Nav,
    Navbar,
    Row,
    Badge,
    Container,
    Alert,
    Col,
} from "react-bootstrap";
import { FontAwesomeIcon } from "./fontawesome";
import * as fa from "@fortawesome/free-solid-svg-icons";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";

const BottomNav = () => {
    const nav = React.useRef();
    const [height, setHeight] = React.useState(80);

    React.useEffect(() => {
        nav.current && setHeight(nav.current.offsetHeight);
    }, [nav]);

    return (
        <>
            <Helmet>
                {/* https://stackoverflow.com/a/14735425 */}
                <style type="text/css">
                    {`body {padding-bottom: ${height}px}`}
                </style>
            </Helmet>
            <Navbar className="border-top" fixed="bottom" bg="white" ref={nav}>
                <Nav className="w-100 justify-content-center d-flex container">
                    <Nav.Item className="col-4">
                        <Link
                            activeClassName="active"
                            to="/"
                            className="d-flex flex-column text-center nav-link"
                        >
                            <FontAwesomeIcon
                                icon={fa.faHome}
                                className="w-100"
                            />
                            <span>Beranda</span>
                        </Link>
                    </Nav.Item>
                    <Nav.Item className="col-4">
                        <Link
                            activeClassName="active"
                            to="/transaction/"
                            className="d-flex flex-column text-center nav-link"
                        >
                            <span className="fa-layers fa-fw w-100">
                                <FontAwesomeIcon
                                    icon={fa.faLongArrowAltRight}
                                    transform="up-4"
                                />
                                <FontAwesomeIcon
                                    icon={fa.faLongArrowAltLeft}
                                    transform="down-4"
                                />
                            </span>
                            <span>Transaksi</span>
                        </Link>
                    </Nav.Item>
                    <Nav.Item className="col-4">
                        <Link
                            activeClassName="active"
                            to="/account/"
                            className="d-flex flex-column text-center nav-link"
                        >
                            <FontAwesomeIcon
                                icon={fa.faUserCircle}
                                className="w-100"
                            />
                            <span>Akun</span>
                        </Link>
                    </Nav.Item>
                </Nav>
            </Navbar>
        </>
    );
};

export default BottomNav;
